import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import ContainerPage from '../components/ContainerPage'
// import PageTitle from '../components/PageTitle'
import Img from 'gatsby-image'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import styled from 'styled-components'

const MainContent = styled.div`
    width: 100%;
    margin-top: 100px;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      width: 70%;
      padding-right: 5em;
    }
    @media screen and (min-width: ${props => props.theme.responsive.large}) {
      width: 70%;
      padding-right: 7.5em;
    }
` 

const PagePhoto = styled.div`
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 30%;
    margin: 150px auto 0 auto;
  }
`

const PageTemplate = ({ data }) => {
  const { title, slug, body, sidePhoto } = data.contentfulPage
  const postNode = data.contentfulPage
  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />

      <ContainerPage>
        <MainContent>
          <PageBody body={body} />
        </MainContent>
        {sidePhoto && 
          <PagePhoto>
            <Img fluid={sidePhoto.fluid} />
          </PagePhoto>
          }
      </ContainerPage>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      sidePhoto {
        title
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PageTemplate
